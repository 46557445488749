import Vue from 'vue'
import App from './App.vue'
import store from './store'
import router from './router'
import './permission.js'
import './uiLibs/index.js'

import '@/plugins/global-error-log.js'
import notify from '@/plugins/notification.js'
Vue.prototype.$pop = notify

Vue.config.productionTip = false

import moment from 'moment'
Vue.prototype.$moment = 'moment'

new Vue({
  store,
  router,
  render: (h) => h(App)
}).$mount('#app')

