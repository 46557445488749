import router from '@/router'
import store from '@/store'
import { Message } from 'element-ui'
// 路由高于3.0.1版本会报重定向，所以安装路由要安装3.0.1版本或以下
router.beforeEach((to, from, next) => {
  if (to.path === '/user' || store.state.token) {
    return next() 
  } else {
	Message({
	    message: "请先登录",
		duration: 1 * 1000,
	    // type: 'success',
	})
    return next('/user')
  }
})
