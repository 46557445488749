<template>
  <div id="app">
    <router-view></router-view>
  </div>
</template>

<script>

export default {
  name: 'App'
}

</script>

<script>
export default {
	name: 'App',
	data() {
		return {
		}
	},
	mounted() {
	},
	methods: {
	}
}
</script>

<style>
@media screen and (min-width: 1000px){
  #app {
    margin: 20px auto 0 auto;
    width: 1000px;
    /* height: 800px; */
    overflow: hidden;
    background-color: #f3f3f3;
    box-shadow: 0 0 8px 0 rgba(232, 237, 250, 0.6);
  }
}
@media screen and (max-width: 999px){
  #app {
    overflow: hidden;
  }
}
</style>
