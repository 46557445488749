import Vue from 'vue'
import Vuex from 'vuex'
import Cookies from 'js-cookie'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    isMobile: window.innerWidth < 1000,
    client: {},
    token: localStorage.getItem('token') || '',
	userInfo: localStorage.getItem('userInfo') ? JSON.parse(localStorage.getItem('userInfo')) : {},
	//token: Cookies.get('token') || '',
   //userInfo: Cookies.get('userInfo') ? JSON.parse(Cookies.get('userInfo')) : {},
    logs: [],
    nexmoApp: null,
    callList: [],
    callIniting: false
  },
  getters: {
    currentCall(state) {
      return state.callList[0]
    },
    currentCallStatus(state, getters) {
      return getters.currentCall?.status
    },
    currentCallNumber(state, getters) {
      const currentCall = getters.currentCall
      if (!currentCall) return
      if (currentCall.direction === currentCall.CALL_DIRECTION.OUTBOUND) {
        return currentCall.from?.channel?.to?.number || 'unknown'
      } else {
        return currentCall.from || 'unknown'
      }
    }
  },
  mutations: {
    setToken(state, value) {
      state.token = value
	  localStorage.setItem('token',value, { expires: 7 })
      // Cookies.set('token', value, { expires: 7 })
    },
    clearToken(state) {
      state.token = ''
	  localStorage.clear('token')
      // Cookies.remove('token')
    },
    setClient(state, client) {
      state.client = client
    },
    setUserInfo(state, userInfo) {
      state.userInfo = userInfo
	  localStorage.setItem('userInfo', JSON.stringify(userInfo), { expires: 7 })
      // Cookies.set('userInfo', JSON.stringify(userInfo), { expires: 7 })
    },
    addErrorLog(state, log) {
      state.logs.push(log)
    },
    setNexmoApp(state, value) {
      state.nexmoApp = value
    },
    setCallIniting(state, value) {
      state.callIniting = value
    },
    addCall(state, value) {
      state.callList.push(value)
    },
    delCall(state, { isCurrentCall, call }) {
      if (isCurrentCall) {
        state.callList.shift()
      } else {
        const index = state.callList.indexOf(call)
        if (index > -1) {
          state.callList.splice(index, 1)
        }
      }
    }
  },
  actions: {
  },
  modules: {
  }
})
