import Vue from 'vue'

import { Menu, MenuItem, Loading, Icon, Form, FormItem, Input, Button, Table, TableColumn, Pagination, Tag } from 'element-ui'

Vue.use(Menu).use(MenuItem).use(Icon).use(Form).use(FormItem).use(Input).use(Button).use(Table).use(TableColumn).use(Pagination).use(Tag)
Vue.use(Loading.directive)
import { Message } from 'element-ui'
const $Message = {
  success(message = '成功', duration = 1500) {
    Message({
      message,
      duration,
      type: 'success',
      showClose: true
    })
  },
  warning(message, duration = 1500) {
    Message({
      message,
      duration,
      type: 'warning',
      showClose: true
    })
  },
  info(message, duration = 1500) {
    Message({
      message,
      duration,
      type: 'info',
      showClose: true
    })
  },
  error(message = '错误', duration = 1500) {
    Message({
      message,
      duration,
      type: 'error',
      showClose: true
    })
  }
}

Vue.prototype.$msgOk = $Message.success
Vue.prototype.$msgWarning = $Message.warning
Vue.prototype.$msgInfo = $Message.info
Vue.prototype.$msgError = $Message.error
export const $message = $Message

