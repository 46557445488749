import { $message } from '@/uiLibs/index.js'

const voice = (function() {
  var audio = document.createElement('audio')
  audio.src = '/music/callin.mp3'
  return function() {
    audio.play()
  }
})()

function pop(message = '您有新的来电！', options = {},contextMessage={}) {
	//options.image = '/image/check.gif'
	options.icon = '/image/check_vv.png'
	options.silent = false
  voice()
  try {
    const notification = new Notification(message, options)
    notification.onclick = function() {
      window.focus()
      this.close()
    }
	// notification.onclose = function() {
	// 	console.log("关闭")
	// }
  } catch (e) {
    // 移动端有Notification，但是构造不出来通知
  }
}

// pc端浏览器弹出来电提示
function notify(message, options) {
  if (window.Notification) {
    if (Notification.permission === 'granted') {
      return pop(message, options)
    }
    if (Notification.permission === 'default') {
      const p = Notification.requestPermission()
      p.then(res => {
        if (res === 'granted') {
          pop(message, options)
        } else {
          $message.error('禁用通知，会导致收不到来电弹窗！')
        }
      })
    }
  }
}

function checkNotify() {
  if (window.Notification) {
    if (Notification.permission === 'granted') {
      return
    }
    if (Notification.permission === 'default') {
      const p = Notification.requestPermission()
      p.then(res => {
        if (res !== 'granted') {
          $message.error('禁用通知，会导致收不到来电弹窗！')
        }
      })
    }
    if (Notification.permission === 'denied') {
      $message.error('已禁用通知！请在地址栏左侧开启')
    }
  } else {
    $message.error('警告：此浏览器不支持消息弹窗功能，请升级浏览器或使用最新版谷歌Chrome浏览器')
  }
}

checkNotify()

export default notify
