<template>
  <div class="home">
    <div class="home-menu">
      <el-menu :default-active="defaultActive" router class="home-menu-vertical" :collapse="collapse" :mode="mode" active-text-color="#409EFF">
        <el-menu-item index="/communication">
          <i class="el-icon-phone"></i>
          <span slot="title">通话</span>
        </el-menu-item>
        <el-menu-item index="/log">
          <i class="el-icon-s-order"></i>
          <span slot="title">记录</span>
        </el-menu-item>
        <el-menu-item index="/user">
          <i class="el-icon-user-solid"></i>
          <span slot="title">用户</span>
        </el-menu-item>
        <el-menu-item index="/setting">
          <i class="el-icon-setting"></i>
          <span slot="title">设置</span>
        </el-menu-item>
      </el-menu>
    </div>
    <div class="home-panel">
      <keep-alive><router-view></router-view></keep-alive>
    </div>
  </div>
</template>

<script>
export default {
  computed: {
    defaultActive() {
      return this.$route.path
    },
    mode() {
      return this.$store.state.isMobile ? 'horizontal' : 'vertical'
    },
    collapse() {
      return !this.$store.state.isMobile
    }
  }
}
</script>

<style lang="scss" scoped>
@media screen and (min-width: 1000px){
  .home-menu{
    display: inline-block;
	width: 65px;
    .home-menu-vertical.el-menu--collapse {
      height: 800px;
      .el-icon-phone,.el-icon-s-order,.el-icon-s-comment,.el-icon-user-solid,.el-icon-setting{
        font-size: 25px;
      }
    }
  }
  .home-panel{
    float: right;
    width: 935px;
    height: 800px;
  }
}

@media screen and (max-width: 999px){
	.home{
		height: 100vh;
	}
  .home-menu{
    .el-menu-item{
      box-sizing: border-box;
      width: 25%;
    }
    .el-icon-phone,.el-icon-s-order,.el-icon-s-comment,.el-icon-user-solid,.el-icon-setting{
        font-size: 25px;
      }
  }
  .home-panel{
    height: 100%;
  }
}
</style>
