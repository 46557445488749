import Vue from 'vue'
import Router from 'vue-router'
import Home from '@/views/Home.vue'

Vue.use(Router)

const routes = [
  {
    path: '/',
    component: Home,
    redirect: '/communication',
    children: [
      {
        path: 'communication',
        component: () => import('@/views/Communication/Communication.vue')
      },
      {
        path: 'log',
        component: () => import('@/views/Log/Log.vue')
      },
      {
        path: 'user',
        component: () => import('@/views/User/User.vue')
      },
      {
        path: 'Setting',
        component: () => import('@/views/Setting/Setting.vue')
      }
    ]
  }
]

export default new Router({ routes })
